import { Tab, useMediaQuery, useTheme } from "@mui/material";
import { Fragment, Suspense } from "react";
import { generatePath, Link, Outlet, useNavigate } from "react-router-dom";
import { LinkTabs } from "../../components/LinkTabs";
import {
  useCurrentPath,
  useOrganization,
  useOrganizationId,
} from "../../hooks";
import * as paths from "../../Router/paths";

interface TabItemDef {
  label: string;
  to: string;
}

const items: TabItemDef[] = [
  {
    label: "About",
    to: paths.companyAbout,
  },
  {
    label: "People",
    to: paths.companyPeople,
  },
];

export const CompanyPageWrapper = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const breakpointMdOrMore = useMediaQuery(theme.breakpoints.up("md"));
  const organizationId = useOrganizationId();
  const currentPath = useCurrentPath();
  const currentTabValue =
    items.find(({ to }) => [to].includes(currentPath || ""))?.to ||
    items[0]?.to;
  const { error } = useOrganization(organizationId);

  if (error) {
    navigate("/" + paths.dealsAll);
  }

  return (
    <div className="m9-container">
      <LinkTabs fullWidth={breakpointMdOrMore} value={currentTabValue}>
        {items.map(({ label, to }, index) => {
          return (
            <Tab
              key={index}
              value={to}
              className="text-[16px] py-6 px-2 font-bold capitalize hover:text-primary-400 transition-colors ease-in"
              component={Link}
              to={generatePath("/" + to, { id: organizationId })}
              label={label}
            />
          );
        })}
      </LinkTabs>

      <div className="py-8">
        <Suspense fallback={<Fragment />}>
          <Outlet />
        </Suspense>
      </div>
    </div>
  );
};
