import {
  FieldValues,
  UseControllerProps,
  useController,
} from "react-hook-form";
import {
  FormInputWrapper,
  FormInputWrapperProps,
} from "ui/components/FormInputWrapper";
import MultiSelectChips, { MultiSelectChipsProps } from "../MultiSelectChips";

interface Props<T> extends UseControllerProps<T>, FormInputWrapperProps {
  showError?: boolean;
}

const MultiSelectChipsController = <T extends FieldValues>({
  name,
  control,
  showError,
  label,
  labelAction,
  allowCollapse,
  collapseInitialState,
  helperText,
  tooltip,
  ...props
}: Props<T> & Omit<MultiSelectChipsProps, "value" | "onChange">) => {
  const { field, fieldState } = useController({
    name,
    control,
  });

  return (
    <div className="flex flex-col">
      <FormInputWrapper
        label={label}
        errorMessage={showError ? fieldState?.error?.message : ""}
        labelAction={labelAction}
        allowCollapse={allowCollapse}
        collapseInitialState={collapseInitialState}
        helperText={helperText}
        tooltip={tooltip}
      >
        <MultiSelectChips {...field} {...props} />
      </FormInputWrapper>
    </div>
  );
};

export default MultiSelectChipsController;
