export enum EnabledFeaturesEnum {
  DealsAndProjects = "DealsAndProjects",
  ProjectsOnly = "ProjectsOnly",
  DealsOnly = "DealsOnly",
}

export type EnabledFeaturesState =
  | EnabledFeaturesEnum.DealsAndProjects
  | EnabledFeaturesEnum.ProjectsOnly
  | EnabledFeaturesEnum.DealsOnly
  | undefined;

export interface EnabledFeatures {
  projects: boolean;
  deals: boolean;
}

export const useEnabledFeatures = () => {
  // add real config here when backend is ready
  // const {enabledFeatures} = useSyndicationConfig();

  const enabledFeatures: EnabledFeatures = { projects: false, deals: true };

  const enabledFeaturesState = (
    features: EnabledFeatures
  ): EnabledFeaturesState => {
    if (features.projects && features.deals) {
      return EnabledFeaturesEnum.DealsAndProjects;
    } else if (features.projects) {
      return EnabledFeaturesEnum.ProjectsOnly;
    } else if (features.deals) {
      return EnabledFeaturesEnum.DealsOnly;
    }
  };

  return enabledFeaturesState(enabledFeatures);
};
