import React, { useMemo } from "react";
import { ReactComponent as LogoDarkNoTextSVG } from "assets/svg/logo-e9-dark-no-text.svg";
import { ReactComponent as LogoLightNoTextSVG } from "assets/svg/logo-e9-light-no-text.svg";
import { useNavigate } from "react-router-dom";
import { useSyndicationLayout, useTypedSelector } from "ui/hooks";
import * as paths from "ui/Router/paths";

interface Props {
  light?: boolean;
}

const LogoNoText = ({ light }: Props) => {
  const navigate = useNavigate();
  const layout = useSyndicationLayout();
  const { authorized } = useTypedSelector(({ auth }) => auth);

  const content = useMemo(() => {
    if (layout.isSyndicate) {
      return (
        <img
          className="h-11"
          src={
            light
              ? layout.mainInfo?.logo?.logoSquare
              : layout.mainInfo?.logo?.logoSquare
          }
          alt={`${layout.mainInfo.legalName} logo`}
        />
      );
    }

    return light ? <LogoLightNoTextSVG /> : <LogoDarkNoTextSVG />;
  }, [layout, light]);

  return (
    <div
      className="flex items-center leading-[0] cursor-pointer"
      onClick={() => {
        if (!authorized || !layout.isSyndicate) {
          return navigate("/");
        }

        navigate("/" + paths.dealsAll);
      }}
    >
      {content}
    </div>
  );
};

export default LogoNoText;
