import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  EnabledFeaturesEnum,
  EnabledFeaturesState,
  useEnabledFeatures,
  useErrorHandler,
  useSyndicationLayout,
} from "ui/hooks";
import * as paths from "ui/Router/paths";
import {
  BasicInformationSharedData,
  RegistrationDealsOnly,
  RegistrationDealsProjects,
  RegistrationFormOrderSyndication,
  RegistrationProjectsOnly,
  RegistrationStepPaths,
} from "./RegistrationForm.domain";
import { RegistrationStepEnum } from "./RegistrationForm.enums";
import { IRegistrationSteps } from "types";

export const useRegistrationOrder = () => {
  const enabledFeatures = useEnabledFeatures();

  const registrationOrder = (
    state: EnabledFeaturesState
  ): { order: RegistrationStepEnum[]; steps: (keyof IRegistrationSteps)[] } => {
    switch (state) {
      case EnabledFeaturesEnum.DealsAndProjects:
        return {
          order: RegistrationDealsProjects,
          steps: [
            "qualifyAsInvestor",
            "position",
            "accreditation",
            "interests",
            "signatureDocument",
          ],
        };
      case EnabledFeaturesEnum.ProjectsOnly:
        return { order: RegistrationProjectsOnly, steps: [] };
      case EnabledFeaturesEnum.DealsOnly:
        return {
          order: RegistrationDealsOnly,
          steps: [
            "position",
            "accreditation",
            "interests",
            "signatureDocument",
          ],
        };
      default:
        return { order: [], steps: [] };
    }
  };

  return useMemo(() => registrationOrder(enabledFeatures), [enabledFeatures]);
};

export const useRegistrationFormUtils = (
  currentStep: RegistrationStepEnum,
  customOrder?: RegistrationStepEnum[]
) => {
  const navigate = useNavigate();
  const { handleError } = useErrorHandler();
  const { isSyndicate } = useSyndicationLayout();
  const { order } = useRegistrationOrder();

  const defaultRoute = useMemo(() => {
    return paths.dealsAll;
  }, []);

  const localRegistrationFormOrderLocal = useMemo(
    () =>
      customOrder || (isSyndicate ? RegistrationFormOrderSyndication : order),
    [isSyndicate, customOrder, order]
  );

  const getRouteByIncrement = useCallback(
    (increment: number) => {
      const selectedStepIndex =
        localRegistrationFormOrderLocal.indexOf(currentStep) + increment;
      const selectedStep = localRegistrationFormOrderLocal[selectedStepIndex];

      return RegistrationStepPaths[selectedStep] || defaultRoute;
    },
    [currentStep, defaultRoute, localRegistrationFormOrderLocal]
  );

  const nextRoute = useMemo(
    () => getRouteByIncrement(1),
    [getRouteByIncrement]
  );

  const previousRoute = useMemo(
    () => getRouteByIncrement(-1),
    [getRouteByIncrement]
  );

  const navigateToNextRoute = useCallback(
    (state?: BasicInformationSharedData) => {
      navigate("/" + nextRoute, state ? { state } : undefined);
    },
    [navigate, nextRoute]
  );

  const navigateToPreviousRoute = useCallback(() => {
    navigate("/" + previousRoute);
  }, [navigate, previousRoute]);

  const onError = (error: unknown) => {
    handleError(error, "It was not possible to get the registration data.");
  };

  return {
    nextRoute,
    previousRoute,
    navigateToNextRoute,
    navigateToPreviousRoute,
    onError,
  };
};
