import React from "react";
import { IMaskInput } from "react-imask";

interface Props {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const CurrencyInput = React.forwardRef(function CurrencyInput(
  { onChange, ...props }: Props,
  ref: React.ForwardedRef<HTMLElement>
) {
  return (
    <IMaskInput
      mask="$num"
      {...props}
      blocks={{
        num: {
          // nested masks are available!
          mask: Number,
          thousandsSeparator: ",",
        },
      }}
      lazy={false}
      inputRef={ref as any}
      onAccept={(value: any) =>
        onChange({ target: { name: props.name, value } })
      }
      overwrite
    />
  );
});

export default CurrencyInput;
