import { Menu, MenuProps, Theme } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";

const StyledMenu = styled((props: MenuProps) => (
  <Menu disableAutoFocusItem elevation={0} {...props} />
))(({ theme }: { theme?: Theme }) => {
  return {
    "& .MuiPaper-root": {
      borderRadius: 8,
      border: `1px solid ${theme?.customColors?.gray?.[300]}`,
      marginTop: theme?.spacing(1),
      minWidth: 185,
      color: theme?.customColors?.gray?.[500],
      boxShadow: "0px 64px 64px -48px rgba(31, 47, 70, 0.12);",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
      "& .MuiMenuItem-root": {
        padding: "10px 16px",
        position: "relative",
        fontSize: "1rem",
        lineHeight: "1.5rem",

        "& .MuiListItemIcon-root": {
          minWidth: "unset",

          "& > *": {
            marginRight: 8,
          },
        },

        "&:not(:first-of-type):before": {
          width: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          height: "1px",
          content: '" "',
          borderTop: `1px solid ${theme?.customColors?.gray?.[200]}`,
        },

        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme?.palette.text.secondary,
          marginRight: theme?.spacing(1.5),
        },

        "&:hover, &:active, &:focus": {
          color: theme?.customColors?.text?.dark,
          backgroundColor: theme?.customColors?.gray?.[150],
        },
      },
    },
  };
});

export { StyledMenu };
