import { IFeatureFlagConfig, IGetFeatureFlag } from "types/featureFlagService";
import { apiClient, uninterceptedApiClient } from "./clients";

class FeatureFlagService {
  private readonly basePath = "/FeatureFlag";

  async getAllFlags(): Promise<IGetFeatureFlag> {
    const { data } = await uninterceptedApiClient.get(this.basePath);
    return data.response;
  }

  async updateFlags(
    flags: Record<string, IFeatureFlagConfig>
  ): Promise<string> {
    const payload = { flags };
    const { data } = await apiClient.post(this.basePath, payload);
    return data.response;
  }
}

export default new FeatureFlagService();
