import { Check, CopyAll } from "@mui/icons-material";
import { Button, Tab, Typography } from "@mui/material";
import { delayAsync } from "application/utils";
import { parseDigits } from "libphonenumber-js";
import { useSnackbar } from "notistack";
import { FC, useCallback, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { usePhoneUtils } from "ui/hooks";
import ModalComponent from "ui/pages/CommonLayout/Modals/ModalComponent";
import * as paths from "ui/Router/paths";
import { AutocompleteController } from "../AutocompleteController";
import { ClipboardInput } from "../ClipboardInput";
import { FormInput, FormInputController } from "../FormInput";
import { FormInputWrapper } from "../FormInputWrapper";
import { LinkTabs } from "../LinkTabs";
import Loading from "../Loading/Loading";
import TabPanel from "../TabPanel/TabPanel";
import resolver from "./SyndicateModal.validation";

interface SyndicateModalProps {
  isOpen: boolean;
  isLoading: boolean;
  shareLink?: string;
  onSendEmail: (recipientAddress: string, message: string) => Promise<void>;
  onSendSMS: (recipientPhoneNumber: string) => Promise<void>;
  onClose: () => void;
}

export interface FormFields {
  emailAddress: string;
  emailMessage: string;
  countryCode: Option | null;
  phoneNumber: string;
}

const defaultEmailMessageValue =
  "Please, find an invitation for joining me on the Emerge9 platform." +
  "\n\n" +
  "Emerge9 provides exclusive access to a diversified set of co-investments with a focus on liquidity and transparency. ";

const defaultValues: FormFields = {
  countryCode: null,
  emailAddress: "",
  emailMessage: defaultEmailMessageValue,
  phoneNumber: "",
};

export const SyndicateModal: FC<SyndicateModalProps> = ({
  isOpen,
  onClose,
  onSendEmail,
  onSendSMS,
  isLoading,
  shareLink,
}) => {
  const [copyFeedback, setCopyFeedback] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { control, watch, handleSubmit } = useForm<FormFields>({
    defaultValues,
    resolver,
  });
  const { formatByDialingCode, countryOptions } = usePhoneUtils();
  const [tabValue, setTabValue] = useState(0);

  const countryCodeValue = watch("countryCode");

  const onCopyEmailMessage = useCallback(async () => {
    await navigator.clipboard.writeText(defaultEmailMessageValue);

    setCopyFeedback(true);

    await delayAsync(5000);

    setCopyFeedback(false);
  }, []);

  const onSubmit: SubmitHandler<FormFields> = async ({
    countryCode,
    emailAddress,
    emailMessage,
    phoneNumber,
  }) => {
    if (!(phoneNumber || emailAddress)) {
      enqueueSnackbar(
        'An "email address" or "phone number" is required to apply for the syndicate.',
        {
          title: "Invalid form",
          variant: "warning",
        }
      );

      return;
    }

    if (phoneNumber) {
      const parsedNumber = "+" + parseDigits(countryCode?.value + phoneNumber);

      await onSendSMS(parsedNumber);
    }

    if (emailAddress) {
      await onSendEmail(emailAddress, emailMessage);
    }

    enqueueSnackbar("The syndicate has been applied successfully.", {
      title: "Sent successfully",
      variant: "success",
    });
  };

  return (
    <ModalComponent
      smallTitle
      title="Share the deal link"
      onClose={onClose}
      open={isOpen}
    >
      {isLoading ? (
        <div className="min-h-[400px] flex w-full">
          <Loading full />
        </div>
      ) : (
        <div className="flex flex-col space-y-7">
          <div className="rounded-xl bg-primary-200 bg-opacity-10 py-4 pl-3 pr-8">
            <Typography variant="body2" className="text-gray-500">
              As licensed broker dealer, you could earn commissions from brining
              in investments through your referrals. Your commission rate for
              this deal is [X] BPS.
              <br />
              <br />
              All details you can find in{" "}
              <Link to={"/" + paths.dealsAll}>
                <Typography variant="link" className="decoration-0">
                  Syndications{" "}
                </Typography>
              </Link>
              section.
            </Typography>
          </div>
          <div>
            <LinkTabs
              fullWidth
              centered
              value={tabValue}
              onChange={(_, newValue) => setTabValue(newValue as any)}
              variant="fullWidth"
              height="unset"
            >
              <Tab
                value={0}
                className="py-4 px-2 font-bold text-gray-400 hover:text-primary-400 transition-colors ease-in w-full"
                style={{ textTransform: "none" }}
                label={
                  <Typography variant="button1" className="text-inherit">
                    Copy and paste link
                  </Typography>
                }
              />
              <Tab
                value={1}
                className="py-3 px-2 font-bold text-gray-400 hover:text-primary-400 transition-colors ease-in w-full"
                style={{ textTransform: "none" }}
                label={
                  <Typography variant="button1" className="text-inherit">
                    Send via mail or SMS
                  </Typography>
                }
              />
            </LinkTabs>
            <TabPanel index={0} value={tabValue} className="space-y-8 pt-8">
              <FormInputWrapper label="Link to the deal">
                <ClipboardInput
                  name="shareLink"
                  placeholder="About your deal in a few words"
                  readOnly
                  value={shareLink || "Link not found"}
                />
              </FormInputWrapper>

              <FormInput
                name="emailMessage"
                label="Email message"
                multiline
                minRows={7}
                placeholder="About your deal in a few words"
                readOnly
                value={defaultEmailMessageValue}
              />
              <div className="space-y-4 flex flex-col">
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  startIcon={copyFeedback ? <Check /> : <CopyAll />}
                  onClick={onCopyEmailMessage}
                >
                  {copyFeedback ? "Copied" : "Copy and paste to your email"}
                </Button>
                <Button
                  onClick={onClose}
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  Done
                </Button>
              </div>
            </TabPanel>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TabPanel index={1} value={tabValue} className="pt-8">
                <Typography
                  variant="body2"
                  className="font-bold text-gray-400 mb-5"
                >
                  Send link via email
                </Typography>
                <div className="space-y-8">
                  <FormInputController
                    name="emailAddress"
                    control={control}
                    label="Email"
                    showError
                    placeholder="Email address"
                    value="https://userfriendlylinl.ro_the_deal"
                  />
                  <FormInputController
                    name="emailMessage"
                    control={control}
                    label="Email message"
                    multiline
                    minRows={7}
                    placeholder="The email message"
                  />
                </div>
                <Typography
                  variant="body2"
                  className="font-bold text-gray-400 mb-5 mt-5"
                >
                  Send link via SMS
                </Typography>
                <div className="space-y-8">
                  <div className="col-span-12 sm:col-span-6 flex">
                    <div className="w-[180px] mr-4">
                      <AutocompleteController
                        name="countryCode"
                        control={control}
                        disableClearable
                        options={countryOptions}
                        label="Country Code"
                        placeholder="Code"
                        showError
                      />
                    </div>
                    <Controller
                      name="phoneNumber"
                      control={control}
                      defaultValue=""
                      render={({ field, fieldState }) => (
                        <div className="w-full">
                          <FormInputWrapper
                            errorMessage={fieldState?.error?.message}
                          >
                            <FormInput
                              {...field}
                              label="Phone Number"
                              error={Boolean(fieldState.error)}
                              placeholder="(XXX) XXX-XXXX"
                              value={formatByDialingCode(
                                countryCodeValue?.value,
                                field.value as any
                              )}
                            />
                          </FormInputWrapper>
                        </div>
                      )}
                    />
                  </div>
                </div>
                <div className="space-y-4 flex flex-col mt-8">
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    Send
                  </Button>
                </div>
              </TabPanel>
            </form>
          </div>
        </div>
      )}
    </ModalComponent>
  );
};
