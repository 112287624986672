import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import * as paths from "ui/Router/paths";

export const useSyndicationPartnerId = (
  routeToRedirectWhenNotFound: string = paths.dealsAll
) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { partnerId } = useParams();

  if (!partnerId) {
    navigate("/" + routeToRedirectWhenNotFound);
    enqueueSnackbar("The partner could not be found. Please try again later.", {
      variant: "error",
      title: "Partner not found",
    });
    return "";
  }

  return partnerId;
};
