import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import LoadingButton from "@mui/lab/LoadingButton";
import { Divider, Grid, Typography } from "@mui/material";
import { FeedbackError } from "application/errors";
import { AxiosError } from "axios";
import { isFunction, noop } from "lodash";
import { useSnackbar } from "notistack";
import React, {
  FormEvent,
  FormEventHandler,
  ReactElement,
  useCallback,
  useMemo,
} from "react";
import { FormState } from "react-hook-form";
import { Link, Navigate, useNavigate } from "react-router-dom";
import dealService from "services/deal.service";
import { Loading } from "ui/components";
import { PageTitle } from "ui/components/PageTitle";
import { useDealGeneralData, useDealId, useSyndicationLayout } from "ui/hooks";
import { useErrorHandler } from "ui/hooks/useErrorHandler";
import * as paths from "ui/Router/paths";
import { DealEditPageType } from "./DealForm.interface";

interface BaseDealEditProps extends React.ComponentPropsWithoutRef<"div"> {
  title: string;
  type: DealEditPageType;
  onSubmit: FormEventHandler;
  onReset?: () => void;
  onSkip?: () => void;
  submit?: (event: React.MouseEvent<HTMLElement>) => void;
  isLoading: boolean;
  isSubmitting: boolean;
  disabled?: boolean;
  submitLabel?: string;
  uploadSection?: React.ReactElement;
  error?: Error | AxiosError;
  formState: FormState<unknown>;
  allowPublish?: boolean;
  caption?: string | ReactElement;
}

interface TypeFeedback {
  defaultSubmitLabel: string;
  titlePrefix: string;
}

const LABEL_SAVE_WITHOUT_PUBLISH = "Save without publishing";

export const DealFormLayout = React.forwardRef(function DealFormLayout(
  {
    title,
    children,
    onSubmit,
    onReset,
    onSkip,
    submit,
    isLoading,
    isSubmitting,
    submitLabel,
    uploadSection,
    formState,
    error,
    type,
    allowPublish,
    disabled,
    caption = "After completing all the data, we will study your proposal and send you an email ASAP when everything is ready. In the meantime your Deal will appear as Draft-Unpublished.",
  }: BaseDealEditProps,
  ref: React.ForwardedRef<HTMLFormElement>
) {
  const dealId = useDealId();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { getSummarizedError, handleError } = useErrorHandler();
  const { data } = useDealGeneralData();
  const { platformShortName } = useSyndicationLayout();

  const showPublishButton =
    !data?.isPublished && (allowPublish || type === DealEditPageType.Edit);

  const onPublish = async (e: FormEvent<Element>) => {
    try {
      if (!dealId) {
        throw new FeedbackError("The deal was not found.");
      }

      await onSubmit(e);

      await dealService.publishDeal(dealId, { isPublished: true });

      enqueueSnackbar("The deal has been saved and published successfully.", {
        title: "Published",
        variant: "success",
      });
    } catch (e) {
      handleError(e, "It was not possible to publish the deal");
    }
  };

  const onCancel = useCallback(() => {
    const routeTo = dealId
      ? paths.dealDetails.replace(":alias", data?.alias || dealId)
      : paths.dealsAll;

    navigate("/" + routeTo);
  }, [data?.alias, dealId, navigate]);

  const handleSubmit = useCallback(
    async (e: FormEvent<Element>) => {
      e.preventDefault();
      await onSubmit(e);
      if (!formState.isValid) return;
    },
    [formState.isValid, onSubmit]
  );

  const { defaultSubmitLabel, titlePrefix } = useMemo<TypeFeedback>(() => {
    switch (type) {
      case DealEditPageType.Create:
        return {
          titlePrefix: "Create Deal",
          defaultSubmitLabel: showPublishButton
            ? LABEL_SAVE_WITHOUT_PUBLISH
            : "Continue",
        };
      case DealEditPageType.Edit:
      default:
        return {
          titlePrefix: "Edit Deal",
          defaultSubmitLabel: showPublishButton
            ? LABEL_SAVE_WITHOUT_PUBLISH
            : "Save",
        };
    }
  }, [showPublishButton, type]);

  if (error) {
    if (getSummarizedError(error)?.responseStatusCode === 403) {
      enqueueSnackbar("You don't have access for this content.", {
        title: "No Access",
        variant: "error",
      });

      return <Navigate to={"/" + paths.dealsAll} />;
    }

    throw error;
  }

  if (isLoading) {
    return <Loading full />;
  }

  const isSkippable =
    type === DealEditPageType.Create &&
    !formState.isDirty &&
    isFunction(onSkip);
  const buttonSubmitLabel = submitLabel || defaultSubmitLabel;

  return (
    <div className="m9-container h-full">
      <PageTitle
        subtitle={
          <>
            Promote and manage several funds on {platformShortName}. Consult{" "}
            <Link to={"/" + paths.termsOfService}>Terms & Conditions</Link> for
            more details.
          </>
        }
      >
        {titlePrefix} - {title}
      </PageTitle>
      <div className="mt-10">
        <form ref={ref} onSubmit={handleSubmit}>
          <Grid
            columnSpacing={{ md: 8 }}
            rowSpacing={{ xs: 6, md: 0 }}
            container
            className="flex min-h-full justify-center"
          >
            {uploadSection && (
              <Grid item xs={12} md={5} lg={4}>
                {uploadSection}
              </Grid>
            )}
            <Grid
              item
              xs={12}
              md={uploadSection ? 7 : 9}
              lg={uploadSection ? 8 : 9}
            >
              <div className="flex flex-col space-y-16">{children}</div>
              <div className="mt-16">
                <Typography variant="caption" className="text-gray-400">
                  {caption}
                </Typography>
                <Divider className="mt-5" />
                <div className="flex flex-col md:flex-row justify-between mt-10">
                  <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-1">
                    <LoadingButton
                      loading={isSubmitting}
                      type="submit"
                      disabled={disabled}
                      variant="contained"
                      color="primary"
                      className="w-full md:min-w-[100px] md:w-auto"
                      onClick={
                        isSkippable
                          ? (e) => {
                              e?.preventDefault();
                              onSkip();
                            }
                          : submit || noop
                      }
                    >
                      {isSkippable ? "Skip" : buttonSubmitLabel}
                    </LoadingButton>
                    {showPublishButton && (
                      <LoadingButton
                        loading={isSubmitting}
                        variant="contained"
                        color="primary"
                        className="w-full md:min-w-[100px] md:w-auto"
                        disabled={isSkippable || disabled}
                        onClick={onPublish}
                      >
                        Publish
                      </LoadingButton>
                    )}
                    <LoadingButton
                      loading={isSubmitting}
                      disabled={disabled}
                      color="primary"
                      className="w-full md:min-w-[100px] md:w-auto"
                      onClick={onCancel}
                    >
                      Cancel
                    </LoadingButton>
                  </div>
                  {!isLoading && !isSubmitting && onReset && (
                    <LoadingButton
                      startIcon={<HighlightOffIcon />}
                      onClick={() => onReset()}
                      color="primary"
                      disabled={disabled}
                      loading={isLoading}
                    >
                      Clear all
                    </LoadingButton>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
});
