import { FC, Suspense, useMemo } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { HorizontalStepper, Loading } from "ui/components";
import { useFeatureFlag } from "ui/hooks/useFeatureFlag";
import * as paths from "ui/Router/paths";
import { REGISTRATION_STEPS } from "./RegistrationWrapper.constants";

const RegistrationWrapper: FC = () => {
  const { isLoading } = useFeatureFlag();
  const { pathname } = useLocation();
  const activeStep = useMemo(() => {
    switch (pathname.slice(1)) {
      case paths.signUpWelcome:
      case paths.signUpNda:
        return 3;
      case paths.signUpFollow:
      case paths.signUpInterests:
        return 2;
      case paths.signUpPosition:
      case paths.signUpAccreditation:
      case paths.qualifyAsInvestor:
        return 1;
      case paths.signUpPath:
      case paths.signUpEmailConfirmation:
      case paths.signUpPhoneConfirmation:
      default:
        return 0;
    }
  }, [pathname]);

  return (
    <div className="flex flex-col w-full">
      <HorizontalStepper steps={REGISTRATION_STEPS} currentStep={activeStep} />
      <Suspense fallback={<></>}>
        {isLoading ? <Loading full /> : <Outlet />}
      </Suspense>
    </div>
  );
};

export default RegistrationWrapper;
