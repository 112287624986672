import { capitalize, isUndefined } from "lodash";
import { useCallback, useMemo } from "react";
import { useDealProperties } from "./useDealProperties";

export interface IUseLookupEnums {
  getSectorNameFromId: (id: string) => string;
  getEquityTypeNameFromId: (id: string) => string;
  getAssetTypeNameFromId: (id: string) => string;
  getDealTypeNameFromId: (id: string) => string;
  getGeographicalRegionFromId: (id: string) => string;
  geographicalRegionTypes: Option[];
  sectorTypes: Option[];
  assetTypes: Option[];
  dealTypes: Option[];
  equityTypes: Option[];
  loading: boolean;
}

export const useLookupEnums = (): IUseLookupEnums => {
  const { data: properties, error } = useDealProperties();
  const loading = useMemo(
    () => [properties, error].every(isUndefined),
    [error, properties]
  );

  const sectorTypes = useMemo(() => properties?.sector || [], [properties]);
  const assetTypes = useMemo(() => properties?.asset || [], [properties]);
  const equityTypes = useMemo(() => properties?.equity || [], [properties]);
  const dealTypes = useMemo(() => properties?.deal || [], [properties]);
  const geographicalRegionTypes = useMemo(
    () => properties?.geographicalRegion || [],
    [properties]
  );

  const parseManually = useCallback((id = "") => {
    const parsedId =
      id
        .split("-")
        .map((item: string) => item?.toLowerCase().replace("and", "&"))
        .join(" ") || "Invalid sector";

    return capitalize(parsedId);
  }, []);

  const getFromEnumOrParse = useCallback(
    (id = "", enums: Option[]) => {
      const fromEnum = enums.find(({ value }) => value === id)?.label;

      if (fromEnum) {
        return fromEnum;
      }

      return parseManually(id);
    },
    [parseManually]
  );

  const getSectorNameFromId = useCallback(
    (id) => getFromEnumOrParse(id, sectorTypes),
    [getFromEnumOrParse, sectorTypes]
  );

  const getGeographicalRegionFromId = useCallback(
    (id) => getFromEnumOrParse(id, geographicalRegionTypes),
    [getFromEnumOrParse, geographicalRegionTypes]
  );

  const getDealTypeNameFromId = useCallback(
    (id) => getFromEnumOrParse(id, dealTypes),
    [getFromEnumOrParse, dealTypes]
  );

  const getEquityTypeNameFromId = useCallback(
    (id) => getFromEnumOrParse(id, equityTypes),
    [getFromEnumOrParse, equityTypes]
  );

  const getAssetTypeNameFromId = useCallback(
    (id) => getFromEnumOrParse(id, assetTypes),
    [getFromEnumOrParse, assetTypes]
  );

  const lookupEnums = useMemo(
    () => ({
      getSectorNameFromId,
      getEquityTypeNameFromId,
      getAssetTypeNameFromId,
      getDealTypeNameFromId,
      getGeographicalRegionFromId,
      geographicalRegionTypes,
      sectorTypes,
      assetTypes,
      dealTypes,
      equityTypes,
      loading,
    }),
    [
      assetTypes,
      dealTypes,
      equityTypes,
      geographicalRegionTypes,
      getAssetTypeNameFromId,
      getDealTypeNameFromId,
      getEquityTypeNameFromId,
      getGeographicalRegionFromId,
      getSectorNameFromId,
      loading,
      sectorTypes,
    ]
  );

  return lookupEnums;
};
