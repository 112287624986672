import { Checkbox } from "@mui/material";
import clsx from "clsx";
import { FC, ReactElement } from "react";
import { MenuButton, MenuButtonItem } from "../MenuButton";

interface CardTableItemProps {
  isChecked: boolean;
  onSelect: () => void;
  actions?: ReactElement | ReactElement[] | null;
  menuItems?: MenuButtonItem[];
}

export const CardTableItem: FC<CardTableItemProps> = ({
  isChecked,
  onSelect,
  children,
  actions,
  menuItems,
}) => {
  return (
    <div
      className={clsx(
        "flex items-center justify-between mb-2 min-h-[78px] rounded-lg py-4 px-3 border-b-gray-150 border-b-2 border-solid border-0",
        {
          "bg-gray-150": isChecked,
          "bg-white": !isChecked,
        }
      )}
    >
      <div className="flex items-center">
        <Checkbox className="mr-4" checked={isChecked} onChange={onSelect} />
        {children}
      </div>

      {(actions || menuItems) && (
        <div className="flex items-center space-x-3 ml-4">
          {actions}
          {menuItems && <MenuButton items={menuItems} />}
        </div>
      )}
    </div>
  );
};
