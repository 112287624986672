import { Check, ErrorOutline, SvgIconComponent } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import clsx from "clsx";
import React, { FC, useMemo } from "react";
import { PageHeader, PageHeaderProps } from "../PageHeader";

interface ResultScreenProps extends PageHeaderProps {
  button?: {
    onSubmit: () => void | Promise<void>;
    title?: string;
    icon?: JSX.Element;
    loading?: boolean;
  };
  variant: "error" | "success" | "warning" | "info";
  customIcon?: SvgIconComponent | JSX.Element;
}

interface VariantData {
  color: string;
  icon: SvgIconComponent | JSX.Element;
  defaultTitle?: string;
}

export const ResultScreen: FC<ResultScreenProps> = ({
  title,
  subtitle,
  variant,
  button,
  customIcon,
}) => {
  const { color, icon, defaultTitle } = useMemo<VariantData>(() => {
    switch (variant) {
      case "success":
        return {
          color: "bg-primary-200",
          icon: <Check />,
        };
      case "info":
        return {
          color: "bg-blue-400",
          icon: <ErrorOutline />,
          defaultTitle: "Information",
        };
      case "warning":
        return {
          color: "bg-yellow-400",
          icon: <ErrorOutline />,
          defaultTitle: "Warning",
        };
      case "error":
        return {
          color: "bg-error",
          icon: <ErrorOutline />,
          defaultTitle: "Something went wrong",
        };
      default:
        return { color: "bg-gray-400", icon: <ErrorOutline /> };
    }
  }, [variant]);

  return (
    <div>
      <div className="flex flex-col items-center">
        {customIcon ? (
          customIcon
        ) : (
          <div
            className={clsx(
              "flex justify-center items-center h-16 w-16 rounded-2xl text-white",
              color
            )}
          >
            {icon}
          </div>
        )}
        <div className="mt-5">
          <PageHeader title={title || defaultTitle} subtitle={subtitle} />
        </div>
        {button && (
          <LoadingButton
            className="mt-5"
            onClick={button.onSubmit}
            variant="contained"
            color={"primary"}
            disabled={button.loading}
            endIcon={button?.icon}
          >
            {button.title}
          </LoadingButton>
        )}
      </div>
    </div>
  );
};
