import React from "react";
import clsx from "clsx";

interface Props {
  children: React.ReactNode;
  className?: string;
}

const CategoryChip = ({ children, className }: Props) => {
  return (
    <span
      className={clsx(
        "p-2 bg-primary-600 rounded-[4px] text-gray-100 text-[0.75rem] leading-[0.75rem] uppercase font-mono font-bold h-fit",
        className
      )}
    >
      {children}
    </span>
  );
};

export default CategoryChip;
