export enum AuthGroupEnum {
  Admin = "emerge9-su-group",
  Investor = "emerge9-investor-group",
  FundManager = "emerge9-fund-manager-group",
  User = "emerge9-default-website-user-group",
  FeaturedInvestor = "emerge9-featured-investor-group",
  Developer = "emerge9-developer-group",
}

export enum InvestorQualificationEnum {
  Retail = "retail",
  Accredited = "accredited",
  QualifiedClient = "qualifiedClient",
  QualifiedPurchaser = "qualifiedPurchaser",
}

export const InvestorQualificationNameMap = {
  [InvestorQualificationEnum.Accredited]: "Accredited",
  [InvestorQualificationEnum.QualifiedClient]: "Qualified Client",
  [InvestorQualificationEnum.QualifiedPurchaser]: "Qualified Purchaser",
  [InvestorQualificationEnum.Retail]: "Retail",
};

export const AuthGroupEnumNameMap = {
  [AuthGroupEnum.Admin]: "Admin",
  [AuthGroupEnum.Investor]: "Investor",
  [AuthGroupEnum.FundManager]: "Fund Manager",
  [AuthGroupEnum.User]: "User",
  [AuthGroupEnum.FeaturedInvestor]: "Featured Investor",
  [AuthGroupEnum.Developer]: "Developer",
};

export enum OnboardingEnum {
  None = "",
  Started = "started",
  Done = "done",
  Skipped = "skipped",
}

export enum NDAStatusEnum {
  Unknown = "unknown",
  Loading = "loading",
  NotStarted = "not_started",
  Started = "started",
  Completed = "completed",
  Failed = "failed",
}

export enum RegistrationProgressEnum {
  Initial = "Initial",
  Skipped = "Skipped",
  Completed = "Completed",
  Required = "Required",
}

export const RegistrationStepEnumNameMap = {
  [RegistrationProgressEnum.Initial]: "Initial",
  [RegistrationProgressEnum.Skipped]: "Skipped",
  [RegistrationProgressEnum.Completed]: "Completed",
  [RegistrationProgressEnum.Required]: "Required",
};

export enum AccreditationEnum {
  None = "None",
  Series76582License = "Series76582License",
  BetweenOneAndTwoMils = "BetweenOneAndTwoMils",
  BetweenTwoAndFiveMils = "BetweenTwoAndFiveMils",
  BetweenFiveAndTwentyFiveMils = "BetweenFiveAndTwentyFiveMils",
  AtLeastFiveMils = "AtLeastFiveMils",
  AtLeastTwentyFiveMils = "AtLeastTwentyFiveMils",
  AllQualified = "AllQualified",
  AllQualifiedIndividually = "AllQualifiedIndividually",
}

export const AccreditationEnumNameMap = {
  [AccreditationEnum.None]: "Not accredited",
  [AccreditationEnum.Series76582License]: "Series 7, 65 or 82 license holders",
  [AccreditationEnum.BetweenOneAndTwoMils]:
    "Between $1m and $2.2m in net assets",
  [AccreditationEnum.BetweenTwoAndFiveMils]:
    "Between $2.2m and $5m in net assets",
  [AccreditationEnum.BetweenFiveAndTwentyFiveMils]:
    "Between $5m and $25m in net assets",
  [AccreditationEnum.AtLeastFiveMils]: "At least $5m in investments",
  [AccreditationEnum.AtLeastTwentyFiveMils]: "At least $25m in investments",
  [AccreditationEnum.AllQualified]:
    "All our firm/fund owners are qualified purchasers",
  [AccreditationEnum.AllQualifiedIndividually]:
    "All our firm/fund owners are individually accredited",
};

export enum PositionEnum {
  InvestmentBanker = "investment-banker",
  WealthAdvisor = "wealth-advisor",
  FundManager = "fund-manager",
  IndividualInvestor = "individual-investor",
  Recruiter = "recruiter",
  PlacementAgent = "placement-agent",
  FamilyOffice = "family-office",
  Other = "other",
}

export const PositionEnumNameMap = {
  [PositionEnum.InvestmentBanker]: "Investment banker",
  [PositionEnum.WealthAdvisor]: "Wealth advisor",
  [PositionEnum.FundManager]: "Fund manager",
  [PositionEnum.IndividualInvestor]: "Individual investor",
  [PositionEnum.Recruiter]: "Recruiter",
  [PositionEnum.PlacementAgent]: "Placement agent",
  [PositionEnum.FamilyOffice]: "Family office",
  [PositionEnum.Other]: "Other",
};
