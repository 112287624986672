import { ColDef } from "ag-grid-community";
import { CountryCode } from "libphonenumber-js/types";

export type TypeColDef<T> = Omit<ColDef, "field"> & { field?: keyof T };

export type PaginationState = Omit<PaginationData, "totalItems">;

export const DEFAULT_PLATFORM_ID = "EMERGE9";

export enum FetchStatus {
  NotStarted = "Not Started",
  Started = "Started",
  Finished = "Finished",
  Error = "Error",
}

export const SESSION_STORAGE_AVOID_PAGE_LOADING_KEY =
  "SESSION_STORAGE_AVOID_PAGE_LOADING_KEY";

export interface PaginationData {
  pageNumber: number;
  pageSize: number;
  totalItems: number;
}

export enum CommonLinks {
  Blog = "https://emerge9.medium.com/",
}

export interface ImageUploadResponse {
  imageId?: string;
  imageUrl: string;
}

export enum FormatType {
  Number = "Number",
  Date = "Date",
  LongDate = "LongDate",
  Text = "Text",
  Markdown = "Markdown",
  Money = "Money",
  MoneyCompact = "MoneyCompact",
  Duration = "Duration",
  Phone = "phone",
  Percent = "percent",
}

export enum SocialNetworkName {
  LinkedIn = "linkedin",
  Twitter = "twitter",
  Instagram = "instagram",
}

export const defaultBrandingColors: IBrandingColors = {
  mainColor: "#136741",
  mainColor0: "#A0DBC3",
  mainColor100: "#6CD2A5",
  mainColor200: "#5BCF9B",
  mainColor300: "#2F9E6C",
  mainColor400: "#0E5133",
  mainColor600: "#093622",
  textColor: "#03140C",
};

export const SUPPORTED_PHONE_COUNTRIES: CountryCode[] = [
  "AR",
  "AU",
  "AT",
  "BE",
  "BR",
  "BG",
  "CA",
  "CL",
  "CN",
  "CO",
  "CZ",
  "DK",
  "EC",
  "EG",
  "EE",
  "FI",
  "FR",
  "DE",
  "HK",
  "HU",
  "IN",
  "IE",
  "IL",
  "IT",
  "JP",
  "JE",
  "LR",
  "LT",
  "LU",
  "MY",
  "MX",
  "MA",
  "MM",
  "NL",
  "NZ",
  "NO",
  "PE",
  "PH",
  "PL",
  "PT",
  "RO",
  "RU",
  "SG",
  "SK",
  "ZA",
  "ES",
  "SE",
  "CH",
  "TW",
  "TH",
  "UA",
  "AE",
  "GB",
  "US",
  "UY",
];

export const DEFAULT_CURRENCY = "USD";

export const SUPPORTED_CURRENCIES = [
  "AED",
  "ARS",
  "AUD",
  "BGN",
  "BHD",
  "BRL",
  "CAD",
  "CHF",
  "CLP",
  "CNY",
  "COP",
  "CZK",
  "DKK",
  "EUR",
  "GBP",
  "HKD",
  "HUF",
  "IDR",
  "ILS",
  "INR",
  "JPY",
  "KRW",
  "MXN",
  "MYR",
  "NOK",
  "NZD",
  "PEN",
  "PHP",
  "PLN",
  "RON",
  "RUB",
  "SAR",
  "SEK",
  "SGD",
  "THB",
  "TRY",
  "TWD",
  "USD",
  "ZAR",
];

export const CURRENCY_MAP = SUPPORTED_CURRENCIES.map((currencyKey) => {
  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currencyKey,
  });
  const currencyDisplayName = new Intl.DisplayNames(["en"], {
    type: "currency",
  }).of(currencyKey);
  const currencySymbol = currencyFormatter.formatToParts(1)[0].value.trim();

  return {
    key: currencyKey,
    displayName: currencyDisplayName,
    symbol: currencySymbol,
  };
});
