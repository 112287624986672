import React from "react";
import clsx from "clsx";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";
import { useSyndicationLayout } from "ui/hooks";

interface INavigationItem {
  text: string;
  to?: string;
  external?: boolean;
}

export interface NavigationItemProps {
  title: string;
  navItems: INavigationItem[];
  footer?: boolean;
  hiddenInLargerScreen?: boolean;
  hiddenForSyndicate?: boolean;
}

export const NavigationItem = ({
  title,
  navItems,
  footer,
  hiddenInLargerScreen,
  hiddenForSyndicate,
}: NavigationItemProps) => {
  const theme = useTheme();
  const breakpointMdOrMore = useMediaQuery(theme.breakpoints.up("md"));
  const { isSyndicate } = useSyndicationLayout();

  if (hiddenInLargerScreen && breakpointMdOrMore) {
    return null;
  }

  if (hiddenForSyndicate && isSyndicate) {
    return null;
  }

  return (
    <div>
      <Typography
        component="p"
        variant="body2"
        className={clsx("font-bold text-primary pb-5", {
          "text-gray-100 pb-10": footer,
        })}
      >
        {title}
      </Typography>
      {Boolean(navItems.length) && (
        <div
          className={clsx("space-y-4 flex flex-col", {
            "space-y-6": footer,
          })}
        >
          {navItems.map(({ text, to = "#", external }, index) => {
            const content = (
              <Typography
                component="p"
                variant="button2"
                className="font-bold text-gray-400 hover:text-primary"
              >
                {text}
              </Typography>
            );

            if (external) {
              return (
                <a
                  key={index}
                  href={to}
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  {content}
                </a>
              );
            }

            return (
              <Link key={index} to={to}>
                {content}
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default NavigationItem;
