import { CancelOutlined } from "@mui/icons-material";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button, Grid, Tooltip } from "@mui/material";
import { FC, FormEventHandler, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import * as paths from "ui/Router/paths";
import {
  Breadcrumbs,
  HorizontalStepper,
  IBreadcrumbsItem,
  PageTitle,
  StepperProgressType,
} from "ui/components";
import { useDealAlias } from "ui/hooks";
import { DealCampaignStepEnum } from ".";
import {
  DEAL_CAMPAIGN_STEP_LABELS,
  DEAL_CAMPAIGN_STEP_ORDER,
} from "./DealEditCampaignForm.domain";

interface DealCampaignLayoutFormProps {
  onSubmit: FormEventHandler;
  title: string;
  previousStepButtonLabel?: string;
  currentStep: DealCampaignStepEnum;
  isSubmitting?: boolean;
  previousStepRoute: string;
  submitLabel?: string;
  onReset?: () => void;
  limitExceeded?: boolean;
  hideActionButtons?: boolean;
  breadcrumbItems: IBreadcrumbsItem[];
}

export const DealEditCampaignFormLayout: FC<DealCampaignLayoutFormProps> = ({
  currentStep,
  onSubmit,
  title,
  children,
  isSubmitting,
  previousStepRoute,
  onReset,
  submitLabel,
  limitExceeded,
  hideActionButtons,
  breadcrumbItems,
}) => {
  const navigate = useNavigate();
  const dealAlias = useDealAlias();

  const currentStepIndex = useMemo(
    () => DEAL_CAMPAIGN_STEP_ORDER.indexOf(currentStep),
    [currentStep]
  );

  const isFirstStep = useMemo(() => currentStepIndex === 0, [currentStepIndex]);
  const isLastStep = useMemo(
    () => currentStepIndex === DEAL_CAMPAIGN_STEP_ORDER.length - 1,
    [currentStepIndex]
  );

  return (
    <div className="m9-container">
      {!dealAlias && (
        <Breadcrumbs
          items={[
            {
              label: "Campaigns",
              to: "/" + paths.campaigns,
            },
            ...breadcrumbItems,
          ]}
        />
      )}
      <div className="flex justify-between items-center">
        <PageTitle>{title}</PageTitle>
      </div>

      <div>
        <HorizontalStepper
          steps={DEAL_CAMPAIGN_STEP_ORDER.map(
            (step) => DEAL_CAMPAIGN_STEP_LABELS[step] || "Step not found"
          )}
          currentStep={currentStepIndex}
          progressType={StepperProgressType.EndOfLabel}
        />
      </div>
      <form className="mt-14" onSubmit={onSubmit}>
        <Grid container spacing={{ xs: 4, md: 6, lg: 8 }}>
          <Grid item xs={12}>
            {children}
            <hr className="w-full border border-solid border-gray-200 my-10" />
            <div className="flex flex-col md:flex-row justify-between space-y-2 md:space-y-0">
              {!hideActionButtons && (
                <div className="flex flex-col md:flex-row md:space-x-2 space-y-2 md:space-y-0">
                  <Button
                    onClick={() => navigate(previousStepRoute)}
                    className="w-full md:w-auto"
                    variant="outlined"
                    disabled={isSubmitting}
                    startIcon={
                      isFirstStep ? null : <ModeEditOutlineOutlinedIcon />
                    }
                  >
                    Cancel
                  </Button>
                  <Tooltip
                    title={"You've reached the monthly limit"}
                    placement="top"
                    open={limitExceeded}
                    disableFocusListener={!limitExceeded}
                    disableHoverListener={!limitExceeded}
                  >
                    <span>
                      <LoadingButton
                        className="w-full md:w-auto"
                        variant="contained"
                        type="submit"
                        loading={isSubmitting}
                        disabled={limitExceeded}
                      >
                        {submitLabel || isLastStep
                          ? submitLabel
                          : "See preview"}
                      </LoadingButton>
                    </span>
                  </Tooltip>
                </div>
              )}
              {onReset && (
                <Button
                  className="w-full md:w-auto"
                  startIcon={<CancelOutlined />}
                  variant="text"
                  disabled={isSubmitting}
                  onClick={() => onReset()}
                >
                  Clear all
                </Button>
              )}
            </div>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
