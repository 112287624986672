import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface UIState {
  showDisclosureModal: boolean;
  showPodcastModal: boolean;
  podcastModalDealId: string | null;
  isOpenProfileDisabled: boolean;
  forceWhiteHeader: boolean;
}

const initialState: UIState = {
  showDisclosureModal: false,
  showPodcastModal: false,
  podcastModalDealId: null,
  isOpenProfileDisabled: false,
  forceWhiteHeader: false,
};

const UISlice = createSlice({
  name: "UI",
  initialState,
  reducers: {
    showDisclosureModal(state: UIState) {
      state.showDisclosureModal = true;
    },
    hideDisclosureModal(state: UIState) {
      state.showDisclosureModal = false;
    },
    showPodcastModal(
      state: UIState,
      { payload }: PayloadAction<string | null>
    ) {
      state.showPodcastModal = true;
      state.podcastModalDealId = payload;
    },
    hidePodcastModal(state: UIState) {
      state.showPodcastModal = false;
      state.podcastModalDealId = null;
    },
    setIsOpenProfileDisabled(
      state: UIState,
      { payload }: PayloadAction<boolean>
    ) {
      state.isOpenProfileDisabled = payload;
    },
    resetUI(state: UIState) {
      state = initialState;
    },
    setForceWhiteHeader(state: UIState, { payload }: PayloadAction<boolean>) {
      state.forceWhiteHeader = payload;
    },
  },
});

export const {
  showDisclosureModal,
  hideDisclosureModal,
  resetUI,
  hidePodcastModal,
  showPodcastModal,
  setIsOpenProfileDisabled,
  setForceWhiteHeader,
} = UISlice.actions;
export default UISlice.reducer;
