import { Card, Typography } from "@mui/material";
import clsx from "clsx";
import React, { FC, HTMLProps } from "react";

interface CardSectionProps {
  title?: string;
  outsideTitle?: string | React.ReactElement;
  actions?: React.ReactElement;
  className?: string;
  containerProps?: HTMLProps<HTMLDivElement>;
  shadow?: boolean;
}

export const CardSection: FC<CardSectionProps> = ({
  title,
  children,
  className,
  actions,
  outsideTitle,
  containerProps = {},
  shadow = true,
}) => (
  <div
    {...containerProps}
    className={clsx("flex flex-col", containerProps?.className)}
  >
    {outsideTitle && (
      <Typography variant="hairline2" className="text-gray-300 mb-3 uppercase">
        {outsideTitle}
      </Typography>
    )}
    <Card
      style={{
        ...(shadow
          ? {
              boxShadow:
                "0px 24.1244px 32.1658px -16.0829px rgba(161, 174, 183, 0.15)",
            }
          : {}),
      }}
      variant="outlined"
      className={clsx("p-6", className)}
    >
      {(title || actions) && (
        <div className="flex justify-between mb-3 items-center">
          <Typography variant="body2" className="text-primary-600 font-bold">
            {title}
          </Typography>
          {actions && <div className="ml-5">{actions}</div>}
        </div>
      )}
      {children}
    </Card>
  </div>
);
