import { AxiosRequestConfig } from "axios";
import {
  IAssistantAllowedRecipient,
  IAssistantThread,
  ICreateAllowedRecipient,
  IDetailedThread,
  ITemplateAttachedField,
} from "types/assistant.service";
import { apiClient, fetchWithAuth } from "./clients";

class DealAssistantService {
  private readonly basePath = (dealId: string) => `fund/${dealId}/assistant`;

  async getFields(dealId: string): Promise<ITemplateAttachedField[]> {
    const { data } = await apiClient.get(`${this.basePath(dealId)}/fields`);

    return data;
  }

  async getThreads(dealId: string): Promise<IAssistantThread[]> {
    const { data } = await apiClient.get(`${this.basePath(dealId)}/threads`);

    return data;
  }

  async getThreadById(
    dealId: string,
    threadId: string
  ): Promise<IDetailedThread> {
    const { data } = await apiClient.get(
      `${this.basePath(dealId)}/threads/${threadId}`
    );

    return data;
  }

  async createFieldThread(
    dealId: string,
    fieldId: string
  ): Promise<IAssistantThread> {
    const { data } = await apiClient.post(
      `${this.basePath(dealId)}/fields/${fieldId}/threads`
    );

    return data;
  }

  async createAndRunFieldThread(
    dealId: string,
    fieldId: string
  ): Promise<IAssistantThread> {
    const { data } = await apiClient.post(
      `${this.basePath(dealId)}/fields/${fieldId}/threads/run`
    );

    return data;
  }

  async populateFieldThread(
    dealId: string,
    threadId: string,
    config?: RequestInit
  ): Promise<ReadableStream> {
    const response = await fetchWithAuth(
      `${this.basePath(dealId)}/threads/${threadId}/field/populate`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        ...config,
      }
    );

    if (!response.body || !response.body.pipeTo) {
      throw new Error("ReadableStream not supported!");
    }

    return response.body;
  }

  async createThread(dealId: string, name: string | null) {
    const { data } = await apiClient.post(`${this.basePath(dealId)}/threads`, {
      name,
    });

    return data;
  }

  async updateThread(dealId: string, threadId: string, name: string) {
    const { data } = await apiClient.put(
      `${this.basePath(dealId)}/threads/${threadId}`,
      {
        name,
      }
    );

    return data;
  }

  async deleteThread(dealId: string, threadId: string): Promise<void> {
    await apiClient.delete(`${this.basePath(dealId)}/threads/${threadId}`);
  }

  async addThreadMessage(
    dealId: string,
    threadId: string,
    content: string
  ): Promise<ReadableStream> {
    const response = await fetchWithAuth(
      `${this.basePath(dealId)}/threads/${threadId}/messages`,
      {
        method: "POST",
        body: JSON.stringify({
          Role: "user",
          content,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.body || !response.body.pipeTo) {
      throw new Error("ReadableStream not supported!");
    }

    return response.body as ReadableStream;
  }

  async toggleDealAssistant(dealId: string, isEnabled: boolean): Promise<void> {
    await apiClient.put(`${this.basePath(dealId)}/enable`, {
      isEnabled,
    });
  }

  async getAllowedRecipients(
    dealId: string,
    config?: AxiosRequestConfig
  ): Promise<PaginatedTokenResponse<IAssistantAllowedRecipient>> {
    const { data } = await apiClient.get(
      `${this.basePath(dealId)}/allowed-recipients`,
      config
    );

    return data;
  }

  async deleteAllowedRecipient(
    dealId: string,
    email: string
  ): Promise<IAssistantAllowedRecipient[]> {
    const { data } = await apiClient.delete(
      `${this.basePath(dealId)}/allowed-recipients`,
      { params: { email } }
    );

    return data;
  }

  async addAllowedRecipients(
    dealId: string,
    payload: ICreateAllowedRecipient[]
  ): Promise<IAssistantAllowedRecipient[]> {
    const { data } = await apiClient.post(
      `${this.basePath(dealId)}/allowed-recipients`,
      payload
    );

    return data;
  }

  async uploadCSVAllowedRecipients(
    dealId: string,
    formData: FormData,
    config?: AxiosRequestConfig
  ) {
    const { data } = await apiClient.post(
      `${this.basePath(dealId)}/allowed-recipients/csv`,
      formData,
      config
    );
    return data;
  }
}

export default new DealAssistantService();
