import { Collapse, FormControl, InputLabel, Typography } from "@mui/material";
import clsx from "clsx";
import { FC, ReactElement, useState } from "react";
import HelperTooltip from "../HelperTooltip";
import { isEmpty } from "lodash";

export interface FormInputWrapperProps {
  errorMessage?: string | string[];
  label?: string | ReactElement;
  tooltip?: {
    id: string;
    title: string;
    content: string;
  };
  helperText?: string | ReactElement | null;
  fullWidth?: boolean;
  className?: string;
  required?: boolean;
  allowCollapse?: boolean;
  collapseInitialState?: boolean;
  labelAction?: ReactElement | null;
  errorMessageClassName?: string;
}

export const FormInputWrapper: FC<FormInputWrapperProps> = ({
  children,
  errorMessage,
  tooltip,
  label,
  helperText,
  fullWidth = true,
  className,
  required,
  allowCollapse,
  collapseInitialState = false,
  labelAction = null,
  errorMessageClassName = "",
}) => {
  const [isCollapsed, setCollapsed] = useState(collapseInitialState);

  return (
    <FormControl
      fullWidth={fullWidth}
      error={!isEmpty(errorMessage)}
      variant="standard"
      className={clsx(className)}
    >
      {label && (
        <InputLabel
          disableAnimation
          shrink
          className={clsx(
            "relative flex items-center pb-3 box-content h-3 space-x-2 overflow-visible",
            allowCollapse && "cursor-pointer"
          )}
          error={!isEmpty(errorMessage)}
          onClick={(e) => {
            if (!allowCollapse) return;

            e.preventDefault();
            e.stopPropagation();
            setCollapsed(!isCollapsed);
          }}
        >
          <span>{label}</span>
          {allowCollapse && (
            <Typography
              variant="caption2"
              className="pl-1 !ml-auto !font-[0.65rem]"
            >
              {isCollapsed ? "Show" : "Hide"}
            </Typography>
          )}
          {required && <span className="text-danger-300">*</span>}
          {tooltip && (
            <HelperTooltip
              id={tooltip.id}
              title={tooltip.title}
              content={tooltip.content}
            />
          )}
          {labelAction}
        </InputLabel>
      )}
      {allowCollapse ? (
        <Collapse in={!isCollapsed}>{children}</Collapse>
      ) : (
        children
      )}
      {!isEmpty(errorMessage) && (
        <Typography
          variant="caption2"
          className={clsx(
            "font-semibold text-error pt-2",
            errorMessageClassName
          )}
        >
          {Array.isArray(errorMessage)
            ? errorMessage.map((item, index) => <div key={index}>{item}</div>)
            : errorMessage}
        </Typography>
      )}
      {helperText && (
        <Typography
          variant="caption2"
          className="font-semibold text-gray-300 pt-2"
        >
          {helperText}
        </Typography>
      )}
    </FormControl>
  );
};
