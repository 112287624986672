import { PhotoLibraryRounded } from "@mui/icons-material";
import { Typography } from "@mui/material";
import clsx from "clsx";
import React, { FC, ReactElement } from "react";
import styles from "./styles.module.scss";

interface EmptyContentScreenProps {
  title: string | ReactElement;
  subtitle: string | ReactElement;
  icon?: ReactElement;
}

export const EmptyContentScreen: FC<EmptyContentScreenProps> = ({
  subtitle,
  title,
  icon = <PhotoLibraryRounded />,
}) => {
  return (
    <div className="flex flex-col min-h-[292px] w-[268px] max-w-full space-y-3 items-center justify-center m-auto !pointer-events-auto">
      <div className={clsx(styles.IconWrapper, "text-gray-400")}>{icon}</div>
      <Typography variant="button1" className="text-gray-400 text-center">
        {title}
      </Typography>
      <Typography variant="button2" className="text-gray-300 text-center">
        {subtitle}
      </Typography>
    </div>
  );
};
