import { PlayArrowRounded } from "@mui/icons-material";
import { CircularProgress, IconButton, IconButtonProps } from "@mui/material";
import clsx from "clsx";
import { FC } from "react";

export enum PlayButtonVariant {
  Small,
  Large,
}

type PlayButtonProps = Omit<
  IconButtonProps,
  "startIcon" | "endIcon" | "children" | "variant"
> & { loading?: boolean; variant?: PlayButtonVariant };

export const PlayButton: FC<PlayButtonProps> = ({
  children: _,
  className,
  loading,
  disabled,
  variant = PlayButtonVariant.Small,
  ...props
}) => {
  const isSmall = variant === PlayButtonVariant.Small;

  return (
    <IconButton
      disabled={loading || disabled}
      className={clsx(
        "bg-primary hover:bg-primary-300 text-white disabled:bg-gray-150",
        {
          "h-11 w-11": isSmall,
          "h-28 w-28": !isSmall,
        },
        className
      )}
      {...props}
    >
      {loading ? (
        <CircularProgress size={isSmall ? 22 : 44} color="inherit" />
      ) : (
        <PlayArrowRounded style={{ fontSize: isSmall ? 30 : 85 }} />
      )}
    </IconButton>
  );
};
