import { Check, Close, ContentCopy } from "@mui/icons-material";
import { Button, IconButton, Typography } from "@mui/material";
import { FeedbackError } from "application/errors";
import { copyTextToClipboard, delayAsync } from "application/utils";
import clsx from "clsx";
import { isFunction } from "lodash";
import Markdown from "markdown-to-jsx";
import { useSnackbar } from "notistack";
import {
  FC,
  HTMLProps,
  KeyboardEvent,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactSyntaxHighlighter from "react-syntax-highlighter";
import { tomorrowNightEighties } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { Key } from "ts-key-enum";
import { IAssistantMessage } from "types/assistant.service";
import { useErrorHandler } from "ui/hooks";
import { CopyButton } from "../Button";
import { MenuButton } from "../MenuButton";
import { ConfirmationModal } from "../Modal";
import styles from "./AssistantChat.module.scss";

export const MessageLoader = () => {
  return (
    <div className={styles.Loader}>
      <div className={styles.DotFlashing}></div>
    </div>
  );
};

interface MarkdownSyntaxHighlightProps {
  className: string;
  children: string;
}

export const MarkdownSyntaxHighlight: FC<MarkdownSyntaxHighlightProps> = ({
  children,
  className,
}) => {
  const language = className?.split("-")?.pop() || "";
  const [displayCopyFeedback, setDisplayCopyFeedback] = useState(false);

  const handleClick = useCallback(async () => {
    await copyTextToClipboard(children);
    setDisplayCopyFeedback(true);

    await delayAsync(5000);

    setDisplayCopyFeedback(false);
  }, [children]);

  return (
    <div>
      <div className="bg-zinc-700 text-white px-4 py-2 w-full rounded-t-md flex justify-between items-center">
        {language && (
          <Typography variant="hairline2" className="text-white capitalize">
            {language}
          </Typography>
        )}
        <Button
          startIcon={
            displayCopyFeedback ? (
              <Check style={{ height: 15 }} />
            ) : (
              <ContentCopy style={{ height: 15 }} />
            )
          }
          onClick={() => {
            if (displayCopyFeedback) return;
            handleClick();
          }}
          className="h-6 text-white hover:bg-gray-50 focus:bg-gray-50 hover:bg-opacity-20 focus:bg-opacity-40"
        >
          <Typography variant="caption" className="text-inherit font-bold">
            {displayCopyFeedback ? "Copied" : "Copy code"}
          </Typography>
        </Button>
      </div>
      <ReactSyntaxHighlighter
        showLineNumbers
        wrapLongLines
        language={language}
        style={tomorrowNightEighties}
        codeTagProps={{ className: "font-mono" }}
        customStyle={{
          marginTop: 0,
          borderRadius: 6,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          paddingBottom: 12,
        }}
      >
        {children}
      </ReactSyntaxHighlighter>
    </div>
  );
};

interface DealAssistantMessageProps {
  message: Pick<IAssistantMessage, "messageId" | "role" | "content">;
  showOptions: boolean;
  onEdit?: (messageId: string, content: string) => Promise<void>;
  onDelete?: (messageId: string) => Promise<void>;
  isUserMessage: boolean;
}

export const DealAssistantMessageWrapper = forwardRef<
  HTMLDivElement,
  HTMLProps<HTMLDivElement>
>((props, ref) => {
  return (
    <div
      ref={ref}
      className={clsx(
        "p-3 bg-white border border-solid border-gray-200 w-fit rounded-md shrink"
      )}
      {...props}
    />
  );
});

export const DealAssistantMessage: FC<DealAssistantMessageProps> = ({
  message,
  showOptions,
  onEdit,
  onDelete,
  isUserMessage,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { handleError } = useErrorHandler();

  const contentRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const markdownRef = useRef<HTMLDivElement>(null);

  const [isEditing, setEditing] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const focus = useCallback(() => {
    const node = contentRef.current;

    if (!node) return;

    const range = document.createRange();
    const sel = window.getSelection();

    if (!sel) return;

    range.selectNodeContents(node);
    range.collapse(false);
    sel.removeAllRanges();
    sel.addRange(range);
    node.focus();
  }, []);

  const actions = useMemo(() => {
    const editAction = isFunction(onEdit)
      ? [
          {
            label: "Edit",
            onClick: async () => {
              setEditing(true);
              await delayAsync(10);
              focus();
            },
          },
        ]
      : [];

    const deleteAction = isFunction(onDelete)
      ? [
          {
            onClick: () => {
              setDeleteModalOpen(true);
            },
            label: "Delete",
          },
        ]
      : [];
    return [...editAction, ...deleteAction];
  }, [focus, onDelete, onEdit]);

  const resetEdit = useCallback(() => {
    if (!contentRef.current) return;

    contentRef.current.innerText = message.content;
    setEditing(false);
  }, [message.content]);

  const handleEdit = useCallback(async () => {
    if (!onEdit) return;
    if (!contentRef.current) {
      throw new Error(
        "It was not possible to edit because the reference could not be found"
      );
    }

    setSubmitting(true);

    try {
      const content = contentRef.current.innerText;

      if (!content) {
        throw new FeedbackError("Please insert a valid value for the message.");
      }

      await onEdit(message.messageId, contentRef.current.innerText);

      setEditing(false);

      enqueueSnackbar("The message has been successfully updated.", {
        title: "Message updated",
        variant: "success",
      });
    } catch (e) {
      handleError(
        e,
        "It was not possible to edit the message. Please try again later"
      );
      resetEdit();
    } finally {
      setSubmitting(false);
    }
  }, [enqueueSnackbar, handleError, message.messageId, onEdit, resetEdit]);

  const handleDelete = useCallback(async () => {
    if (!onDelete) return;

    setSubmitting(true);

    try {
      await onDelete(message.messageId);

      enqueueSnackbar("The message has been successfully deleted.", {
        title: "Message deleted",
        variant: "success",
      });
    } catch (e) {
      handleError(
        e,
        "It was not possible to delete the message. Please try again later."
      );
    } finally {
      setSubmitting(false);
    }
  }, [enqueueSnackbar, handleError, message.messageId, onDelete]);

  useEffect(() => {
    if (!wrapperRef.current) return;
    if (!isEditing) return;

    const content = wrapperRef.current;

    const callback: EventListener = (event) => {
      if (event.target && !content.contains(event.target as any)) {
        resetEdit();
      }
    };

    document.addEventListener("click", callback);

    return () => document.removeEventListener("click", callback);
  }, [isEditing, resetEdit]);

  const copyContent = useCallback(async (reference: HTMLElement | null) => {
    if (!reference) return;

    try {
      const html = reference.innerHTML;
      await navigator.clipboard.write([
        new ClipboardItem({
          "text/html": new Blob([html], { type: "text/html" }),
          "text/plain": new Blob([reference.textContent || ""], {
            type: "text/plain",
          }),
        }),
      ]);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  }, []);

  return (
    <div className="flex w-full items-start justify-between">
      <DealAssistantMessageWrapper key={message.messageId} ref={wrapperRef}>
        <Typography
          component="span"
          variant="caption"
          className="text-gray-500"
        >
          {isUserMessage ? (
            <>
              <div
                ref={contentRef}
                contentEditable={isEditing}
                suppressContentEditableWarning
                style={{ whiteSpace: "pre-wrap" }}
                onKeyDown={async (event: KeyboardEvent) => {
                  if (event.key === Key.Escape) {
                    resetEdit();
                  }
                }}
              >
                {message.content}
              </div>
              {isEditing && (
                <div className="w-full flex mt-2 space-x-2">
                  <IconButton
                    disabled={submitting}
                    onClick={resetEdit}
                    className="h-8 w-8"
                  >
                    <Close />
                  </IconButton>
                  <IconButton
                    disabled={submitting}
                    onClick={handleEdit}
                    className="h-8 w-8"
                  >
                    <Check />
                  </IconButton>
                </div>
              )}
            </>
          ) : (
            <Markdown
              options={{
                forceWrapper: true,
                wrapper: (props) => (
                  <span
                    {...props}
                    ref={markdownRef}
                    className={clsx(styles.Message)}
                  />
                ),
                overrides: {
                  code: MarkdownSyntaxHighlight,
                },
              }}
            >
              {message.content}
            </Markdown>
          )}
        </Typography>
      </DealAssistantMessageWrapper>

      <div className="pl-2 flex flex-col">
        {showOptions && actions.length > 0 && (
          <MenuButton smallItems items={actions} />
        )}
        {!isUserMessage && (
          <CopyButton
            textToCopy=""
            overrideCopyFn={() => copyContent(markdownRef.current)}
            className="sticky top-1"
          />
        )}
      </div>

      {isDeleteModalOpen && (
        <ConfirmationModal
          title="Delete template message"
          isOpen={isDeleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          onConfirm={handleDelete}
        >
          <Typography
            style={{ whiteSpace: "pre-wrap" }}
            variant="body1"
            className="text-gray-500"
          >
            Are you sure to delete the message?
            <br />
            <div className="p-2 mt-3 bg-gray-200 w-fit rounded-lg">
              {message.content}
            </div>
          </Typography>
        </ConfirmationModal>
      )}
    </div>
  );
};
