import { Switch } from "@mui/material";
import { FeedbackError } from "application/errors";
import { MediaStatus } from "domain/media";
import { useCallback, useState } from "react";
import { Link, generatePath } from "react-router-dom";
import mediaService from "services/media.service";
import * as paths from "ui/Router/paths";
import { CardAction } from "ui/components";
import {
  useDealMedia,
  useDealPaths,
  useErrorHandler,
  useGroupValidation,
  useSyndicationLayout,
} from "ui/hooks";
import useDealAlias from "ui/hooks/Deal/useDealAlias";
import { useCampaignQuota } from "ui/hooks/Deal/useDealCommunication";
import { DealAssignOwnerAction } from "../DealAssignOwnerAction/DealAssignOwnerAction";
import { DealManageNavDataRoomWatermark } from "./DealManageNavDataRoomWatermark";
import DealPublishAction from "./DealPublishAction";
import DealQuestionAndAnswersAction from "./DealQuestionAndAnswersAction";
import { DealManageNavPDFDownload } from "./DealManageNavPDFDownload";
import { DealManageNavAssistant } from "./DealManageNavAssistant";
import { DealManageNavDeleteDeal } from "./DealManageNavDeleteDeal";

export const DealManageNavs = () => {
  const {
    rolePermissionsPath,
    userPermissionsPath,
    dealCampaignsPath,
    userCampaignExclusionList,
  } = useDealPaths();
  const { isSyndicate } = useSyndicationLayout();
  const { data, mutate } = useDealMedia();
  const { handleError } = useErrorHandler();
  const { isAdmin, hasAdminLevel } = useGroupValidation();
  const [isPublishingMedia, setPublishingMedia] = useState(false);
  const isPodcastPublished = data?.status === MediaStatus.Published;
  const dealAlias = useDealAlias();
  const { data: campaignQuota } = useCampaignQuota();

  const onPublishToggle = useCallback(
    async (_, value: boolean) => {
      setPublishingMedia(true);
      try {
        if (!data)
          throw new FeedbackError(
            "It was not possible to fetch the media content."
          );

        if (value) {
          return await mediaService.publishMedia(data.id);
        }

        return await mediaService.unpublishMedia(data.id);
      } catch (e) {
        handleError(e, "It was not possible to update published status.");
      } finally {
        await mutate();
        setPublishingMedia(false);
      }
    },
    [data, handleError, mutate]
  );

  return (
    <div className="space-y-6">
      <DealPublishAction />
      {isAdmin && <DealManageNavDataRoomWatermark />}
      {isAdmin && <DealManageNavPDFDownload />}
      <DealQuestionAndAnswersAction />
      {data && (
        <div>
          <CardAction
            label="Deal podcast"
            loading={isPublishingMedia}
            description={
              <span>
                The podcast is currently{" "}
                <b>{isPodcastPublished ? "" : "not "}published</b>.{" "}
                <Link
                  to={generatePath("/" + paths.editDealPodcastReview, {
                    alias: dealAlias,
                    mediaId: data.id,
                  })}
                >
                  See preview
                </Link>
              </span>
            }
            endAdornment={
              <Switch
                checked={data?.status === MediaStatus.Published}
                onChange={onPublishToggle}
              />
            }
          />
        </div>
      )}
      {hasAdminLevel && <DealManageNavAssistant />}
      {isAdmin && (
        <div>
          <Link to={rolePermissionsPath}>
            <CardAction
              label="Roles based permissions"
              description="Manage which roles can access your deal"
            />
          </Link>
        </div>
      )}
      <div>
        <Link to={userCampaignExclusionList}>
          <CardAction
            label="Investor access exclusion list"
            description="Manage which participants could access your deal"
          />
        </Link>
      </div>
      <div>
        <Link to={userPermissionsPath}>
          <CardAction
            label="Individual user permissions"
            description="Manage who can access your deal"
          />
        </Link>
      </div>
      {hasAdminLevel && <DealAssignOwnerAction />}
      {hasAdminLevel && (!isSyndicate || campaignQuota?.isCampaignEnabled) && (
        <div>
          <Link to={dealCampaignsPath}>
            <CardAction
              label="Manage communication"
              description="Manage email preferences and schedules"
            />
          </Link>
        </div>
      )}
      <DealManageNavDeleteDeal />
    </div>
  );
};
