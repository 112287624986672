import Add from "@mui/icons-material/Add";
import Contacts from "@mui/icons-material/Contacts";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Button, Grid, IconButton } from "@mui/material";
import { ReactElement, useMemo, useState } from "react";
import { generatePath, Link, Outlet, useLocation } from "react-router-dom";
import { Breadcrumbs, NavigationDrawer, PageTitle } from "ui/components";
import Aside, { IAsideItem } from "ui/components/Aside/Aside";
import { useContactLists } from "ui/hooks";
import * as paths from "ui/Router/paths";
import CampaignCreateListModal from "../CampaignCreateForm/CampaignCreateListModal";
import useDealAlias from "ui/hooks/Deal/useDealAlias";

interface SubPageItem extends IAsideItem {
  pageTitle: ReactElement;
}

export const CampaignsWrapper = () => {
  const dealAlias = useDealAlias();
  const location = useLocation();
  const { mutate } = useContactLists();
  const completePathname = [location.pathname, location.hash].join("");
  const [isCreateListModalOpen, setIsCreateListModalOpen] = useState(false);
  const breadcrumbsProps = useMemo(() => {
    if (!dealAlias) {
      return {
        items: [
          {
            label: "Back",
            to: "/" + paths.dealsAll,
          },
          {
            label: "Campaign list",
            to: "/" + paths.dealsAll,
          },
        ],
      };
    }

    return {
      items: [
        {
          label: "Deal",
          to: "/" + generatePath(paths.dealDetails, { alias: dealAlias }),
        },
        {
          label: "Manage Deal",
          to: "/" + generatePath(paths.dealManage, { alias: dealAlias }),
        },
        {
          label: "Campaign list",
          to: "/" + generatePath(paths.dealCampaigns, { alias: dealAlias }),
        },
      ],
    };
  }, [dealAlias]);

  const onCreateContactList = () => {
    setIsCreateListModalOpen(true);
  };

  const handleClose = () => {
    setIsCreateListModalOpen(false);
    mutate();
  };

  const campaignsAsideMenuItems = useMemo<SubPageItem[]>(() => {
    return [
      {
        label: "Campaigns",
        Icon: MailOutlineIcon,
        to: dealAlias
          ? generatePath(paths.dealCampaigns, { alias: dealAlias })
          : paths.campaigns,
        pageTitle: (
          <div className="flex justify-between items-start">
            <PageTitle>Campaign list</PageTitle>

            <Link
              to={
                dealAlias
                  ? generatePath("/" + paths.editDealCampaign, {
                      alias: dealAlias,
                    })
                  : "/campaign/create"
              }
            >
              <Button
                className="hidden md:flex"
                startIcon={<Add />}
                variant="contained"
              >
                New campaign
              </Button>
              <IconButton
                className="flex md:hidden bg-primary justify-center ml-8 items-center hover:bg-primary-600 h-12 w-12 text-gray-100"
                color="primary"
              >
                <Add />
              </IconButton>
            </Link>
          </div>
        ),
      },
      {
        label: "Contact lists",
        Icon: Contacts,
        to: dealAlias
          ? generatePath(paths.dealCampaignContactLists, { alias: dealAlias })
          : paths.campaignContactLists,
        pageTitle: (
          <div className="flex justify-between items-start">
            <PageTitle>Campaign list</PageTitle>
            <Button
              className="hidden md:flex"
              startIcon={<Add />}
              variant="contained"
              onClick={onCreateContactList}
            >
              Add contacts
            </Button>
            <IconButton
              className="flex md:hidden bg-primary justify-center ml-8 items-center hover:bg-primary-600 h-12 w-12 text-gray-100"
              color="primary"
              onClick={onCreateContactList}
            >
              <Add />
            </IconButton>
          </div>
        ),
      },
    ] as SubPageItem[];
  }, [dealAlias]);

  const activeSubItem = useMemo(
    () =>
      campaignsAsideMenuItems.find(
        (item) => "/" + item.to === completePathname
      ),
    [campaignsAsideMenuItems, completePathname]
  );

  return (
    <div className="w-full">
      <div className="flex flex-col grow">
        <div className="w-full shrink-0">
          <NavigationDrawer
            className="block md:hidden"
            title="Campaign"
            items={campaignsAsideMenuItems.map((item) => ({
              ...item,
              to: "/" + item.to,
            }))}
          />
        </div>
        <div className="m9-container">
          <Breadcrumbs {...breadcrumbsProps} />
          <div className="py-7">{activeSubItem?.pageTitle}</div>
          <Grid container spacing={4}>
            <Grid item xs={12} md={3} className="hidden md:block">
              <Aside asideItems={campaignsAsideMenuItems} />
            </Grid>
            <Grid item xs={12} md={9}>
              <Outlet />
            </Grid>
          </Grid>
        </div>
      </div>
      <CampaignCreateListModal
        isOpen={isCreateListModalOpen}
        handleClose={handleClose}
        isExclusionList={false}
      />
    </div>
  );
};
