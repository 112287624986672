import React, { FC } from "react";
import { Typography } from "@mui/material";

export const SectionTitle: FC<{ hairline?: boolean }> = ({
  children,
  hairline,
}) => (
  <Typography
    variant={hairline ? "hairline2" : "body2"}
    className="text-primary-600 font-bold"
  >
    {children}
  </Typography>
);
