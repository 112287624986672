import { Auth } from "aws-amplify";
import { RegistrationProgressEnum } from "domain/authorization";
import { SESSION_STORAGE_AVOID_PAGE_LOADING_KEY } from "domain/common";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import userService from "services/user.service";
import {
  RegistrationProgressMap,
  RegistrationStepPaths,
} from "ui/pages/Registration/RegistrationForm/RegistrationForm.domain";
import * as paths from "ui/Router/paths";
import { useErrorHandler } from "./useErrorHandler";
import useTypedSelector from "./useTypeSelector";
import { signOut } from "redux/Auth/actions";
import { useRegistrationOrder } from "ui/pages/Registration/RegistrationForm/RegistrationForm.hooks";

export const useLocationRedirects = () => {
  const [isLoading, setLoading] = useState(
    sessionStorage.getItem(SESSION_STORAGE_AVOID_PAGE_LOADING_KEY) !== "true"
  );
  const navigate = useNavigate();
  const { authorized } = useTypedSelector((state) => state.auth);
  const { qualifyAsInvestor } = useTypedSelector((state) => state.registration);
  const { handleError, getSummarizedError } = useErrorHandler();
  const dispatch = useDispatch();
  const { steps: progressSteps } = useRegistrationOrder();

  const checkAndRedirectToStep = useCallback(async () => {
    if (!authorized) return setLoading(false);

    try {
      const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
      const isEmailVerified = user?.attributes?.email_verified;

      if (!isEmailVerified) {
        return navigate("/" + paths.signUpEmailConfirmation);
      }

      const { response } = await userService.getUserOnboardingSteps();

      const completedRegistration = { ...response, qualifyAsInvestor };

      const targetStepName = progressSteps.find((stepName) =>
        [
          null,
          RegistrationProgressEnum.Initial,
          RegistrationProgressEnum.Required,
        ].includes(completedRegistration[stepName])
      );

      if (!targetStepName) {
        sessionStorage.setItem(SESSION_STORAGE_AVOID_PAGE_LOADING_KEY, "true");
        return;
      }

      const targetStep = RegistrationProgressMap[targetStepName];

      return navigate("/" + RegistrationStepPaths[targetStep]);
    } catch (e) {
      const { responseAPIMessage } = getSummarizedError(e);

      // TODO Fix issue on BE side and prevent it in future, for now it's a quick fix
      // "The level of configured provisioned throughput for the table was exceeded. Consider increasing your provisioning level with the UpdateTable API."
      if (!responseAPIMessage?.match(/was exceeded.*UpdateTable API/)?.length) {
        handleError(
          e,
          "It was not possible to get sign up data. Please sign in and try again."
        );
        await dispatch(signOut());
        navigate("/" + paths.signInPath);
      } else {
        handleError(
          e,
          "We had an internal server error. Please try again later."
        );
      }
    } finally {
      setLoading(false);
    }
  }, [
    authorized,
    dispatch,
    handleError,
    navigate,
    progressSteps,
    getSummarizedError,
    qualifyAsInvestor,
  ]);

  return {
    isLoading,
    checkAndRedirectToStep,
  };
};

export default useLocationRedirects;
