import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RegistrationProgressEnum } from "domain/authorization";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export interface RegistrationState {
  qualifyAsInvestor: RegistrationProgressEnum;
  isFederatedSignUp: boolean;
  isRegularSignUp: boolean;
}

const initialState: RegistrationState = {
  qualifyAsInvestor: RegistrationProgressEnum.Initial,
  isFederatedSignUp: false,
  isRegularSignUp: false,
};

const registrationPersistConfig = {
  key: "registration",
  storage,
  blacklist: ["isFederatedSignUp", "isRegularSignUp"],
};

const registrationState = createSlice({
  name: "registration",
  initialState,
  reducers: {
    setQualifyAsInvestor(
      state,
      { payload }: PayloadAction<RegistrationProgressEnum>
    ) {
      state.qualifyAsInvestor = payload;
    },
    setIsFederatedSignUp(state, { payload }: PayloadAction<boolean>) {
      state.isFederatedSignUp = payload;
    },
    setIsRegularSignUp(state, { payload }: PayloadAction<boolean>) {
      state.isRegularSignUp = payload;
    },
  },
});

export const {
  setQualifyAsInvestor,
  setIsFederatedSignUp,
  setIsRegularSignUp,
} = registrationState.actions;
export default persistReducer(
  registrationPersistConfig,
  registrationState.reducer
);
