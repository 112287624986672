import React, { useState } from "react";
import { SelectProps } from "@mui/material/Select";
import {
  useController,
  UseControllerProps,
  FieldValues,
} from "react-hook-form";
import FormSelect from "./FormSelect";
import { ListItemText, MenuItem, Typography } from "@mui/material";
import clsx from "clsx";

interface Props<T> extends UseControllerProps<T> {
  selectAll?: boolean;
  selectAllDisabled?: boolean;
  handleSelectAll?: (status: boolean) => void;
  showError?: boolean;
  label?: string;
  children: JSX.Element[];
}

const FormSelectController = <T extends FieldValues>({
  name,
  control,
  showError,
  label,
  children,
  className,
  selectAll,
  selectAllDisabled,
  handleSelectAll,
  ...props
}: Props<T> & SelectProps) => {
  const { field, fieldState } = useController({
    name,
    control,
  });

  const [selectAllStatus, setSelectAllStatus] = useState(false);

  return (
    <div className={clsx(className, "flex flex-col")}>
      <FormSelect
        {...props}
        {...field}
        error={showError && Boolean(fieldState.error)}
        label={label}
      >
        {selectAll && (
          <MenuItem
            disabled={selectAllDisabled}
            key="select-all"
            value="select-all"
            className="sticky top-0 bg-primary z-10 !bg-white"
          >
            <ListItemText
              primaryTypographyProps={{ fontWeight: "bold" }}
              primary="Select all"
              onClick={(event) => {
                event.stopPropagation();
                if (handleSelectAll) {
                  handleSelectAll(!selectAllStatus);
                }
                setSelectAllStatus(!selectAllStatus);
              }}
            />
          </MenuItem>
        )}
        {children}
      </FormSelect>
      {showError && fieldState?.error && (
        <Typography
          variant="caption2"
          className="font-semibold text-error pt-2"
        >
          {fieldState.error.message}
        </Typography>
      )}
    </div>
  );
};

export default FormSelectController;
