/* eslint-disable react/prop-types */
import {
  Check,
  Close,
  ErrorOutline,
  SvgIconComponent,
} from "@mui/icons-material";
import { Typography } from "@mui/material";
import clsx from "clsx";
import { CustomContentProps, SnackbarContent, useSnackbar } from "notistack";
import { forwardRef, useCallback, useMemo } from "react";

interface VariantData {
  defaultTitle?: string;
  icon: SvgIconComponent | JSX.Element;
  color: string;
}

interface ContentProps extends CustomContentProps {
  title?: string;
}

const NotificationSnackbar = forwardRef<HTMLDivElement, ContentProps>(
  ({ id, variant, message, title }, ref): JSX.Element => {
    const { closeSnackbar } = useSnackbar();

    const handleDismiss = useCallback(() => {
      closeSnackbar(id);
    }, [id, closeSnackbar]);

    const { color, icon, defaultTitle } = useMemo<VariantData>(() => {
      switch (variant) {
        case "success":
          return {
            color: "bg-green-400",
            icon: <Check />,
          };
        case "info":
          return {
            color: "bg-blue-400",
            icon: <ErrorOutline />,
            defaultTitle: "Information",
          };
        case "warning":
          return {
            color: "bg-yellow-400",
            icon: <ErrorOutline />,
            defaultTitle: "Warning",
          };
        case "error":
          return {
            color: "bg-error",
            icon: <ErrorOutline />,
            defaultTitle: "Something went wrong",
          };
        default:
          return { color: "bg-gray-400", icon: <ErrorOutline /> };
      }
    }, [variant]);

    const validTitle = useMemo(
      () => title || defaultTitle,
      [title, defaultTitle]
    );

    return (
      <SnackbarContent
        style={{ boxShadow: "0px 40px 32px -24px #0F0F0F1F" }}
        className="flex !flex-nowrap justify-between bg-gray-150 rounded-2xl w-[380px] max-w-full min-h-24 items-center p-4"
        ref={ref}
      >
        <div className="flex items-center">
          <div>
            <div
              className={clsx(
                "h-16 w-16 rounded-2xl flex items-center justify-center text-white mr-6",
                color
              )}
            >
              {icon}
            </div>
          </div>
          <div className="mr-7">
            {validTitle && (
              <div className="mb-1">
                <Typography
                  variant="caption2"
                  className="text-dark-text !font-bold"
                >
                  {validTitle}
                </Typography>
              </div>
            )}
            <div>
              <Typography
                variant={validTitle ? "body2" : "caption2"}
                className={validTitle ? "text-gray-400" : "text-dark-text"}
              >
                {message}
              </Typography>
            </div>
          </div>
        </div>

        <div
          className="text-sm cursor-pointer text-gray-400 hover:text-gray-500 transition-colors ease-in-out"
          onClick={handleDismiss}
        >
          <Close />
        </div>
      </SnackbarContent>
    );
  }
);

NotificationSnackbar.displayName = "NotificationSnackbar";

export { NotificationSnackbar };
